import React, {Component} from 'react';
import { Trans } from "gatsby-plugin-react-i18next";

class Steps extends Component {
  render() {
    return (
      <div className="steps">
        <div className={this.props.class? this.props.class : "container"}>
          <p><strong><Trans>deviceInfo.Step</Trans> {this.props.step}</strong> <Trans>deviceInfo.of</Trans> 3</p>
          {this.props.pageTitle && <h2>{this.props.pageTitle}</h2>}
        </div>
      </div>
    );
  }
}

export default Steps;