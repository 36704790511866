import React, { useEffect, useState } from 'react';
import PlanSelection from '../components/product/plan-selection.component';
import Layout from '../components/shared/layout'
import Steps from "../components/shared/steps.component";
import SuccessIconG from "../assets/images/Success-Icon_grey.png"
import { env, isMobile as checkMobile, isTablet as checkTablet, ROUTES } from '../app.constant';
import { handleAmount } from '../app.utils';
import { graphql } from 'gatsby';
import deviceStore from '../store/deviceStore';
import { Trans, useTranslation, useI18next } from "gatsby-plugin-react-i18next";
import mobileIcon from "../assets/images/product/mobile.png";
import tabletIcon from "../assets/images/product/tablet.png";
import wearableIcon from "../assets/images/product/wearable.png";
import editPenIcon from "../assets/images/edit_pen_icon.svg";
import * as constants from "../app.constant"

export default function ProductPage() {
    const { t } = useTranslation()
    const { navigate } = useI18next()
    const [
      {
        commercialPremium = 0,
        acquisitionCost = 0,
        administrativeCost = 0,
        insurancePremiumTax = 0,
        totalPremiumDue = 0,
      } = {},
      setRightPanelData,
    ] = useState();
    const [isMobile, setIsMobile] = useState(false)
    const [isTablet, setIsTablet] = useState(false)
    const [deviceName, setDeviceName] = useState(null)

    useEffect(() => {
      document.title = '';
      setIsMobile(checkMobile())
      setIsTablet(checkTablet())
      getClientDevice();

      // route to step 1 page if no device imei info
      if(deviceStore &&
        (!deviceStore.deviceType || !deviceStore.deviceImei)
      ) {
        localStorage.clear()
        navigate(ROUTES.HOME)
      }
    }, [])


    function getClientDevice() {
        const clientDeviceReq = {
            modelKey: deviceStore.deviceModel,
            key: null,
            modelName: null,
            makeName: null,
            partnerId: [env.GATSBY_PARTNER_CODE],
            currencyCode: null,
            pageIndex: null,
            pageRecordCount: null
        }
        const clientDeviceRequest = {
            method: 'POST',
            headers: {
                "Content-Type": constants.HEADER_REQUEST.contentType,
                "x-api-key": constants.env.GET_PRODUCT_LIST_X_API_KEY,
            },
            body: JSON.stringify(clientDeviceReq)
        };
        // trigger client device API
        fetch(constants.env.GET_CLIENT_DEVICE_BY_MODEL_KEY_API_URL, clientDeviceRequest).then(res => res.json())
            .then(response => {
                if(response.resultList.length > 0){
                    setDeviceName(response.resultList[0].modelName);
                }
                else{
                    setDeviceName(deviceStore.deviceModelDesc);
                }

            })
            .catch(err => {
                console.log("Get Client Device Exception", err)
            })
    }
    
    function onEditImei() {
      localStorage.setItem("isEditImei", true)
      navigate(ROUTES.DEVICE_ELIGIBILITY)
    }

    return (
      <Layout header={true} isBackBtn={true} prevUrl={ROUTES.HOME}>
        <aside className="asideTmp">
          <div className="grid-aside">
            <div>
              <img src={SuccessIconG} alt="success icon" />
            </div>
            <div style={{ color: "#7F7F7F", fontWeight: "5700" }}>
              <p>
                <Trans>desktop.sidebar.step1</Trans>
              </p>
            </div>
            <div></div>
            <div style={{ color: "#2189FF", fontWeight: "700" }}>
              <p>
                <Trans>desktop.sidebar.step2</Trans>
              </p>
            </div>
            <div></div>
            <div style={{ color: "#7F7F7F", fontWeight: "500" }}>
              <p>
                <Trans>desktop.sidebar.step3</Trans>
              </p>
            </div>
          </div>
        </aside>
        {(!(isMobile || isTablet) && env.GATSBY_COUNTRY ==='BE' && !deviceStore.voucherCode) &&(
          <aside className="price-panel">
            { env.GATSBY_COUNTRY !=='BE' &&
             <div className="price-list-container">
               <div>
                 <p><Trans>product.price-panel.commercial</Trans></p>
                 <p>
                   <Trans values={{currency: "€", amount: handleAmount(commercialPremium)}}>
                     product.amount
                   </Trans>
                 </p>
               </div>
               <div>
                 <p><Trans>product.price-panel.acquisition</Trans></p>
                 <p>
                   <Trans values={{currency: "€", amount: handleAmount(acquisitionCost)}}>
                     product.amount
                   </Trans>
                 </p>
               </div>
               <div>
                 <p><Trans>product.price-panel.admin</Trans></p>
                 <p>
                   <Trans values={{currency: "€", amount: handleAmount(administrativeCost)}}>
                     product.amount
                   </Trans>
                 </p>
               </div>
               <div>
                 <p><Trans>product.price-panel.tax</Trans></p>
                 <p>
                   <Trans values={{currency: "€", amount: handleAmount(insurancePremiumTax)}}>
                     product.amount
                   </Trans>
                 </p>
               </div>
               <div>
                 <p><Trans>product.price-panel.total</Trans></p>
                 <p>
                   <Trans values={{currency: "€", amount: handleAmount(totalPremiumDue)}}>
                     product.amount
                   </Trans>
                 </p>
               </div>
             </div>
            }
            <p>
             <Trans>product.notes.n1</Trans>
            </p>
            <p>
             <Trans>product.notes.n2</Trans>
            </p>
            <p>
              <Trans>product.notes.n3</Trans>
            </p>
          </aside>
        )}
        <div className="product-main">
          <div className={isTablet?"device-name tablet":isMobile?"device-name mobile":"device-name web container"}>
            <Steps step="2" pageTitle={t("step.s2")}/>
            <div className={"device-container"}>
              <div className="device-name-bg">
                <div className="device-name-title">
                  <span>
                    <Trans>product.device.title</Trans>
                  </span>
                </div>
                <div className="device-name-container">
                  <div className={isMobile?"device-name-icon mobile":"device-name-icon"}>
                    {deviceStore.deviceType === constants.DEVICE_TYPE.MOBILE && (
                      <img src={mobileIcon} alt="mobile" width="20px" />
                    )}
                    {(deviceStore.deviceType === constants.DEVICE_TYPE.TABLET) && (
                      <img src={tabletIcon} alt="mobile" width="30px" style={{ paddingTop: "5px", paddingBottom: "5px" }}/>
                    )}
                    {deviceStore.deviceType === constants.DEVICE_TYPE.WEARABLE && (
                      <img src={wearableIcon} alt="mobile" width="25px" />
                    )}
                  </div>
                  <div className={"device-name-value"}>
                    {deviceName}
                    <br/>
                    <div className="font-normal pt-10">IMEI: {deviceStore.deviceImei}</div>
                  </div>
                  <div className={"device-edit"}>
                    <img src={editPenIcon} alt="mobile" width="20px" onClick={() => onEditImei()}/>  
                  </div>
                </div>
              </div>
              
            </div>
          </div>
          <PlanSelection toShowPrice={setRightPanelData} />
        </div>
      </Layout>
    )
}

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`